<template>
  <div class="video">
    <iframe
      :src="$route.query.src"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script>
export default {

  created () {
    this.userHasPowerClickLink()
  },

  methods: {
    async userHasPowerClickLink () {
      let token = localStorage.getItem('token')
      let getToken = token || ''
      if (!getToken) {
        this.$router.push('/sign-in1')
        return
      }
      let { id, my_schedule_id, type } = this.$route.query
      let ajaxType = { 1: 'userHasPowerClickLink', 2: 'eventUserHasPowerClickLink', 3: 'courseUserHasPowerClickLink', 4: 'meetingUserHasPowerClickLink' }
      let typeId = { 1: 'project_id', 2: 'event_id', 3: 'course_id', 4: 'meeting_id' }
      let res = await this.$http[ajaxType[type]]({
        [typeId[type]]: id,
        my_schedule_id: my_schedule_id,
        my_schedule_model_type: type,
        link_type: 'broadcast_link'
      })
      if (res.status == 6082) {
        this.$router.push('/newsfeeds')
        // history.go(-1);
      }
    }
  }
}
</script>

<style scoped lang="less">
[v-cloak] {
  display: none;
}
.video_container {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  > .video {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 450px);
    height: 100%;
    background: #fff;
  }

  > .chat-container {
    width: 450px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-left: 1px solid #f1f1f1;
    .title {
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 20px;
      color: #333;
      border-bottom: 1px solid #f1f1f1;
    }

    .chatClass {
      display: flex;
      flex-direction: column;
      height: calc(100% - 270px);
      overflow: auto;
      border-bottom: 1px solid #f1f1f1;
      background: url(./100.jpg) no-repeat scroll center center;
      background-size: cover;

      > li {
        padding: 10px 20px;
        list-style: none;
        > span {
          color: #50b5ff;
        }
        .msg-box {
          display: flex;
          align-items: flex-end;
          .msg-content {
            background: #fff;
            border-radius: 0px 12px 12px 12px;
            padding: 10px;
            color: #333;
            font-size: 14px;
            /* word-break: break-all; */
          }
          .time {
            color: #999;
            margin-left: 18px;
          }
        }
      }

      // 右边聊天
      > li.right {
        padding: 10px 20px;
        list-style: none;

        > span {
          display: block;
          text-align: right;
        }
        > .msg-box {
          display: flex;
          // justify-content: flex-end;
          flex-direction: row-reverse;
          align-items: flex-end;
          .msg-content {
            max-width: 70%;
            background: #fff;
            border-radius: 12px 0px 12px 12px;
            padding: 10px;
            color: #333;
            font-size: 14px;
            /* word-break: break-all; */
          }
          .time {
            color: #999;
            margin-right: 18px;
          }
        }
      }

      // 加入聊天提醒
      > li.join {
        display: flex;
        justify-content: center;
        .msg-content {
          background: rgba(0, 0, 0, 0.1);
          color: #fff;
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 12px;
        }
      }
    }
    .send {
      width: 100%;
      height: 180px;
      position: absolute;
      bottom: 0;
      left: 0;

      ::v-deep .el-textarea__inner {
        // padding: 0;
        border: none;
      }

      .el-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>
